import PageWrapper from '@components/pageWrapper';
import Link from 'next/link';
import Company from 'types/company';

interface pageProps {
  companyID: Company;
}

function NavFooterAccount({ companyID }: pageProps): JSX.Element {
  return (
    <section className={`home-reasons`}>
      <PageWrapper>
        <div className="home-reasons__inner nav-footer">
          <header className="home-reasons__head">
            <Link href="/reasons-to-shop">
              <a>
                <h2>Reasons to shop with us</h2>
              </a>
            </Link>
          </header>
          <div className="home-reasons__body">
            <div className={`home-reasons__features max-w-full`}>
              <div className={`widgets-features`}>
                <div className="widget__main">
                  <div className="widget__inner flex-no-wrap items-center whitespace-no-wrap">
                    <div className="widget__image p-3 md:p-5">
                      <svg
                        className={companyID === Company.Ironmongery ? 'text-orange' : 'text-green'}
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="lock"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
                        ></path>
                      </svg>
                    </div>
                    <div className="widget__content p-0 font-bold">
                      <p>Safe &amp; Secure</p>
                    </div>
                  </div>
                </div>
                <div className="widget__main">
                  <div className="widget__inner flex-no-wrap items-center whitespace-no-wrap">
                    <div className="widget__image p-3 md:p-5">
                      <svg
                        className={companyID === Company.Ironmongery ? 'text-orange' : 'text-green'}
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="credit-card"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M0 432c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V256H0v176zm192-68c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-40zm-128 0c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM576 80v48H0V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48z"
                        ></path>
                      </svg>
                    </div>
                    <div className="widget__content p-0 font-bold">
                      <p>Easy Payments</p>
                    </div>
                  </div>
                </div>
                <div className="widget__main">
                  <div className="widget__inner flex-no-wrap items-center whitespace-no-wrap">
                    <div className="widget__image p-2.5 md:p-5">
                      <svg
                        className={companyID === Company.Ironmongery ? 'text-orange' : 'text-green'}
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="headset"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z"
                        ></path>
                      </svg>
                    </div>
                    <div className="widget__content p-0 font-bold">
                      <p>Contact Support</p>
                    </div>
                  </div>
                </div>
                <div className="widget__main">
                  <div className="widget__inner flex-no-wrap items-center whitespace-no-wrap">
                    <div className="widget__image p-3 md:p-5">
                      <svg
                        className={companyID === Company.Ironmongery ? 'text-orange' : 'text-green'}
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="truck"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h16c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"
                        ></path>
                      </svg>
                    </div>
                    <div className="widget__content p-0 font-bold">
                      <p>Free Returns</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </section>
  );
}

export default NavFooterAccount;
